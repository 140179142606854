.certif-grid {
  display: grid;
  width: 1320px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 15px;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 315px);
}

.certif-grid-element {
  position: relative;
}

.btn-delete-certif {
  position: absolute;
  top: -18px;
  right: 15px;

  display: block;
  border: none;
  background: none;
  padding: 0;
  line-height: 0px;
  font-size: 20px;
  color: #ff0000;
  cursor: pointer;
}

.btn-edit-certif {
  position: absolute;
  top: -18px;
  right: 40px;

  display: block;
  border: none;
  background: none;
  padding: 0;
  line-height: 0px;
  font-size: 20px;
  color: #0000ff;
  cursor: pointer;
}

@media (hover: hover) {
  .btn-delete-certif:hover {
    color: #ff9999;
  }

  .btn-delete-certif:active {
    color: #ff5959;
  }

  .btn-edit-certif:hover {
    color: #9999ff;
  }

  .btn-edit-certif:active {
    color: #5959ff;
  }
}

@media (hover: none) {
  .btn-delete-certif:active {
    color: #ff5959;
  }

  .btn-edit-certif:active {
    color: #5959ff;
  }
}

@media (max-width: 1650px) {
  .certif-grid {
    grid-template-columns: repeat(3, 315px);
    width: 985px;
  }
}

@media (max-width: 1235px) {
  .certif-grid {
    grid-template-columns: repeat(2, 315px);
    width: 650px;
  }
}

@media (max-width: 831px) {
  .certif-grid {
    grid-template-columns: repeat(1, 315px);
    width: 315px;
  }
}

@media (max-width: 414px) {
  .certif-grid {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
