.page-accueil-grid {
  display: grid;
  grid-template: 50px auto 50px auto / 1fr;
  align-items: center;
  margin-top: 25px;
}

.qsj-img,
.mpf-img {
  display: inline-block;
  vertical-align: middle;
  margin-left: 40px;
}

.qsj-titre,
.mpf-titre {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: middle;
  text-decoration: underline;
  font-weight: bold;
  margin-left: 15px;
  font-size: 24px;
}

.qsj-paragraphe,
.mpf-paragraphe {
  margin-left: 85px;
  margin-right: 85px;
  margin-top: 10px;
  text-align: justify;
  font-size: 16px;
}

.qsj-paragraphe {
  margin-bottom: 35px;
}

.mpf-paragraphe {
  margin-bottom: 15px;
}

@media (max-width: 968px) {
  .page-accueil-grid-element-titre {
    text-align: center;
  }

  .qsj-paragraphe,
  .mpf-paragraphe {
    margin-left: 30px;
    margin-right: 30px;
  }

  .qsj-img,
  .mpf-img {
    margin-left: 0;
  }
}
