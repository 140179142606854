.img-err404 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20vh;
}

.titre-err404 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 90%;

  text-align: center;
  font-size: 30px;
}

.paragraphe-err404 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20vh;
  width: 80%;

  font-size: 20px;
  text-align: center;
}

@media (max-width: 250px) {
  .titre-err404 {
    font-size: 50px;
  }

  .img-err404 {
    height: 180px;
    width: 180px;
  }
}
