.grid-mdp-edit {
  display: grid;
  width: 900px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  grid-template: repeat(5, auto) / 1fr 1fr;
  align-items: center;
}

.grid-mdp-edit-element-titre {
  grid-column: 1 / span 2;
  text-align: center;
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 10px;
}

.grid-mdp-edit-element-titre h2 {
  margin-left: 20px;
  margin-right: 20px;
}

.mdp-edit-form-titre {
  font-weight: bold;
  font-size: 20px;
  text-decoration: underline;
  margin-right: 5px;
}

.mdp-edit-form-titre-left {
  text-align: right;
}

#mdpContactEdit,
#mdpConfirmContactEdit {
  display: inline;
  width: 100px;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
  margin-left: 5px;
}

.eye-psw-contact-edit {
  margin-top: 2.6px;
  display: inline !important;
  cursor: pointer;
  color: #000000;
  position: absolute !important;
}

.eye-psw-contact-edit-visible {
  margin-left: 6px;
}

.eye-psw-contact-edit-invisible {
  margin-left: 5px;
}

.grid-mdp-edit-element-long {
  grid-column: 1 / span 2;
}

.btn-contact-mdp-edit-form-valider {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 25px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.message-err-res-mdp-edit-contact {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.message-err-mdp-edit-contact {
  color: #ff0000;
}

.message-res-mdp-edit-contact {
  color: #00ff00;
}

@media (max-width: 1127px) {
  .grid-mdp-edit {
    width: auto;
  }
}

@media (max-width: 750px) {
  .grid-mdp-edit {
    width: auto;
  }

  .grid-mdp-edit-element-left {
    grid-column: 1 / span 2;
  }

  .grid-mdp-edit-element-left p {
    text-align: center;
    margin-right: 0;
  }

  .grid-mdp-edit-element-right {
    grid-column: 1 / span 2;
    margin-left: 0;
    text-align: center;
  }

  #mdpContactEdit,
  #mdpConfirmContactEdit {
    margin-left: 0;
  }
}

@media (hover: hover) {
  .eye-psw-contact-edit:hover {
    color: #a3a3a3;
  }

  .eye-psw-contact-edit:active {
    color: #d7d7d7;
  }

  .btn-contact-mdp-edit-form-valider:hover {
    background-color: #00ca00;
  }

  .btn-contact-mdp-edit-form-valider:active {
    background-color: #39dd39;
  }
}

@media (hover: none) {
  .eye-psw-contact-edit:active {
    color: #d7d7d7;
  }

  .btn-contact-mdp-edit-form-valider:active {
    background-color: #00ca00;
  }
}
