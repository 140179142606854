.navbar-grid {
  display: grid;
  grid-template: 2fr 1fr / 1fr 5fr;
  width: 955px;
  height: 205px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -65px;
}

.navbar-img {
  border: solid #000000 2px;
  box-shadow: 0px 0px 5px rgb(40, 40, 40);
}

.navbar-titre {
  margin-top: 90px;
  margin-left: 30px;
  font-size: 32px;
  margin-bottom: 0;
}

.navbar-grid-img {
  grid-row: 1 / span 2;
  grid-column: 1 / span 1;

  background: radial-gradient(#bcbcbc 50px, #ffffff);
  border-radius: 15px;
  border: solid #000000 2px;
  box-shadow: 0px 0px 25px rgb(40, 40, 40);

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 200px;
  width: 200px;
}

.btn-menu,
.btn-menu-active {
  display: none !important;
  visibility: hidden;
}

.nav-liste {
  padding: 0;
  margin: 0;
}

.nav-liste-element {
  display: inline;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 20px;
}

.nav-liste-element-left {
  display: inline;
  margin-right: 5px;
  margin-left: 30px;
  font-size: 20px;
}

.nav-liste-element-right {
  display: inline;
  margin-right: 5px;
  margin-left: 5px;
  font-size: 20px;
}

.navbar-lien {
  text-decoration: none;
  color: #000000;
}

.navbar-lien-active {
  color: #c8c8c8;
}

@media (max-width: 1215px) {
  .navbar-grid {
    width: 745px;
    height: 159px;
  }

  .navbar-grid-img {
    height: 150px;
    width: 150px;
  }

  .navbar-img {
    height: 84%;
    width: 84%;
  }

  .navbar-titre {
    margin-top: 80px;
    font-size: 25px;
  }

  .nav-liste {
    margin-top: 10px;
  }

  .nav-liste-element {
    font-size: 15px;
  }

  .nav-liste-element-left {
    font-size: 15px;
  }

  .nav-liste-element-right {
    font-size: 15px;
  }
}

@media (max-width: 968px) {
  .navbar-grid {
    grid-template: 2fr 1fr 1fr 1fr / 1fr;
    width: 100%;
    height: 250px;
  }

  .navbar-titre {
    text-align: center;
    margin-left: 0;
    margin-top: 15px;
    font-size: 25px;
  }

  .navbar-grid-nav {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }

  .btn-menu,
  .btn-menu-active {
    visibility: visible;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    border-radius: 8px;
    box-shadow: 0px 0px 5px rgb(40, 40, 40);
    cursor: pointer;
  }

  .btn-menu {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    padding-bottom: 5px;
    border: solid #000000 2px;
    color: #000000;
    background-color: #ffffff;
  }

  .btn-menu-active {
    padding-left: 6.8px;
    padding-right: 6.5px;
    padding-top: 6px;
    padding-bottom: 5px;
    border: solid #ff0000 2px;
    background-color: #ffffff;
    color: #ff0000;
  }

  @-moz-document url-prefix() {
    .btn-menu,
    .btn-menu-active {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 15px;
    }

    .btn-menu {
      padding-left: 6px;
      padding-right: 6px;
    }

    .btn-menu-active {
      padding-left: 6.8px;
      padding-right: 6.5px;
    }

    @media (max-width: 745px) {
      .btn-menu {
        padding-left: 6px;
        padding-right: 6px;
      }

      .btn-menu-active {
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }

  .nav-liste {
    z-index: 1;
    display: none;
    position: absolute;
    padding: 0;
    margin-left: -23px;
    margin-top: 10px;
    width: 150px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px rgb(40, 40, 40);
    border-radius: 10px;
  }

  .nav-liste-active {
    display: block !important;
  }

  .nav-liste-element,
  .nav-liste-element-left,
  .nav-liste-element-right {
    display: block;
    margin-right: 0;
    margin-left: 0;
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .nav-liste-element-left {
    border-top: solid #000000 1px;
    border-left: solid #000000 1px;
    border-right: solid #000000 1px;
    border-radius: 10px 10px 0 0;
  }

  .nav-liste-element {
    border-left: solid #000000 1px;
    border-right: solid #000000 1px;
    border-top: solid #e6e6e6 2px;
  }

  .nav-liste-element-right {
    border-top: solid #e6e6e6 2px;
    border-left: solid #000000 1px;
    border-right: solid #000000 1px;
    border-bottom: solid #000000 1px;
    border-radius: 0 0 10px 10px;
  }

  .nav-liste-element-slash {
    display: none;
    visibility: hidden;
  }

  .navbar-lien-inactive:hover {
    color: #000000 !important;
  }

  .navbar-lien-inactive:active {
    color: #000000 !important;
  }

  .navbar-lien-active:hover {
    color: #000000 !important;
  }

  .navbar-lien-active:active {
    color: #000000 !important;
  }

  .navbar-lien-active {
    color: #000000 !important;
  }

  .menu-deroulant-lien-active {
    background-color: #c8c8c8;
  }

  @media (hover: hover) {
    .menu-deroulant-lien-inactive:hover {
      background-color: #c8c8c8;
    }

    .menu-deroulant-lien-inactive:active {
      background-color: #7f7f7f;
    }

    .menu-deroulant-lien-active:hover {
      background-color: #e5e5e5;
    }

    .menu-deroulant-lien-active:active {
      background-color: #7f7f7f;
    }

    .btn-menu-active:hover {
      background-color: #ff9999;
    }

    .btn-menu-active:active {
      background-color: #ff5959;
    }
  }

  @media (hover: none) {
    .menu-deroulant-lien-inactive:active {
      background-color: #7f7f7f;
    }

    .menu-deroulant-lien-active:active {
      background-color: #7f7f7f;
    }

    .btn-menu-active:active {
      background-color: #ff5959;
    }

    .btn-menu,
    .btn-menu-active {
      font-size: 16px;
    }

    .btn-menu {
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .btn-menu-active {
      padding-left: 7px;
      padding-right: 7px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

@media (hover: hover) {
  .navbar-lien-inactive:hover {
    color: #c8c8c8;
  }

  .navbar-lien-inactive:active {
    color: #7f7f7f;
  }

  .navbar-lien-active:hover {
    color: #e5e5e5;
  }

  .navbar-lien-active:active {
    color: #7f7f7f;
  }

  .btn-menu:hover {
    background-color: #c8c8c8;
  }

  .btn-menu:active {
    background-color: #7f7f7f;
  }
}

@media (hover: none) {
  .navbar-lien-inactive:active {
    color: #7f7f7f;
  }

  .navbar-lien-active:active {
    color: #7f7f7f;
  }

  .btn-menu:active {
    background-color: #7f7f7f;
  }
}
