.page-cv-grid {
  display: grid;
  grid-template: auto auto / 1fr;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.cv-preview {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: solid #000000 2px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px rgb(40, 40, 40);
}

.cv-download-btn {
  display: block;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  width: 100px;
  text-align: center;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #ff0000;
  color: #ffffff;
  text-decoration: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

@media (hover: hover) {
  .cv-download-btn:hover {
    background-color: #ff5050;
  }

  .cv-download-btn:active {
    background-color: #ff2525;
  }
}

@media (hover: none) {
  .cv-download-btn:active {
    background-color: #ff2525;
  }
}

@media (max-width: 968px) {
  .cv-preview {
    height: 100%;
    width: 80%;
  }
}
