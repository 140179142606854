.add-certif-grid {
  display: grid;
  grid-template: 0.1fr 18px / 1fr;
  margin-bottom: 15px;
}

.add-certif-grid-element-form {
  grid-row: 1 / span 1;
}

.add-certif-grid-element-retour {
  grid-row: 2 / span 1;
}

.add-certif-btn {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  color: #000000;
  line-height: 0px;
  font-size: 70px;
  cursor: pointer;
}

.add-certif-btn-retour {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  color: #000000;
  line-height: 16px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

@media (hover: hover) {
  .add-certif-btn:hover {
    color: #c8c8c8;
  }

  .add-certif-btn:active {
    color: #7f7f7f;
  }

  .add-certif-btn-retour:hover {
    color: #c8c8c8;
  }

  .add-certif-btn-retour:active {
    color: #7f7f7f;
  }
}

@media (hover: none) {
  .add-certif-btn:active {
    color: #7f7f7f;
  }

  .add-certif-btn-retour:active {
    color: #7f7f7f;
  }
}
