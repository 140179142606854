.grid-projet {
  display: grid;
  grid-template: 100px 1fr / 125px;
  width: 125px;
  padding: 10px;
  cursor: pointer;

  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.projet-titre {
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
  word-break: break-word;
}

.projet-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.projet-lien {
  display: block;
  text-decoration: none;
  color: #000000;
  width: 145px;
}

.grid-projet-element-img {
  grid-row: 1 / span 1;
}

.grid-projet-element-nom {
  grid-row: 2 / span 1;
}

@media (hover: hover) {
  .grid-projet:hover {
    background-color: #b4b4b450;
    border: solid #38383850 2px;
    border-radius: 5px;
    padding: 8px !important;
  }

  .grid-projet:active {
    background-color: #77777750;
    border: solid #35353550 2px;
    border-radius: 5px;
    padding: 8px !important;
  }
}

@media (hover: none) {
  .grid-projet:active {
    background-color: #77777750;
    border: solid #35353550 2px;
    border-radius: 5px;
    padding: 8px !important;
  }
}
