.btn-lien-grid {
  display: grid;
  grid-template: 0.1fr 0.1fr / 1fr;
  width: 160px;
}

.btn-lien-titre {
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
  font-size: 16px;

  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-lien-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 15px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  color: #000000;
  background-color: #0051ff;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

@media (hover: hover) {
  .btn-lien-btn:hover {
    background-color: #008cff;
  }

  .btn-lien-btn:active {
    background-color: #0000ff;
  }
}

@media (hover: none) {
  .btn-lien-btn:active {
    background-color: #0000ff;
  }
}
