.grid-user-edit {
  display: grid;
  width: 900px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  grid-template: repeat(9, auto) / 1fr 1fr;
  align-items: center;
}

.grid-user-edit-element-titre {
  grid-column: 1 / span 2;
  text-align: center;
  text-decoration: underline;
  margin-top: 0;
  margin-bottom: 10px;
}

.grid-user-edit-element-titre h2 {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

#nomEditUser,
#prenomEditUser,
#numEditUser,
#emailEditUser {
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
  display: inline-block;
  margin-left: 5px;
}

.user-edit-form-titre {
  font-weight: bold;
  font-size: 20px;
  text-decoration: underline;
  margin-right: 5px;
}

.user-edit-form-titre-nom,
.user-edit-form-titre-prenom {
  display: inline-block;
}

.user-edit-form-titre-left {
  text-align: right;
}

.grid-user-edit-element-court {
  text-align: center;
}

.grid-user-edit-element-long {
  grid-column: 1 / span 2;
}

#imgEditUser {
  display: none;
  visibility: hidden;
}

.btn-upload-img-user-edit {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 200px;

  padding-top: 2px;
  padding-bottom: 2px;

  text-align: center;
  font-weight: bold;
  background-color: #ffffff;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
  border-radius: 3px;
  cursor: pointer;
}

.titre-img-deco-user-edit {
  text-decoration: underline;
  font-weight: bold;
}

.grid-user-edit-element-imgInput p {
  text-align: center;
  font-style: italic;
  margin-left: 20px;
  margin-right: 20px;
}

.user-edit-form-titre-long {
  text-align: center;
}

.btn-contact-user-edit-form-valider {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 25px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

@media (max-width: 264px) {
  .btn-upload-img-user-edit {
    width: 130px;
    font-size: 11px;
  }
}

@media (max-width: 836px) {
  .form-grid-register-element-long-imgInput p {
    margin-bottom: 3px;
  }
}

@media (max-width: 1127px) {
  .grid-user-edit {
    width: auto;
  }
}

@media (max-width: 750px) {
  .grid-user-edit {
    width: auto;
  }

  .user-edit-form-titre-nom,
  .user-edit-form-titre-prenom {
    display: block;
    margin-right: 0;
  }

  .grid-user-edit-element-left {
    grid-column: 1 / span 2;
  }

  .grid-user-edit-element-left p {
    text-align: center;
    margin-right: 0;
  }

  .grid-user-edit-element-right {
    grid-column: 1 / span 2;
    margin-left: 0;
  }

  #nomEditUser,
  #prenomEditUser {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
  }

  #numEditUser,
  #emailEditUser {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.message-err-res-user-edit-contact {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.message-err-user-edit-contact {
  color: #ff0000;
}

.message-res-user-edit-contact {
  color: #00ff00;
}

@media (hover: hover) {
  .btn-upload-img-user-edit:hover {
    background-color: #a3a3a3;
  }

  .btn-upload-img-user-edit:active {
    background-color: #d7d7d7;
  }

  .btn-contact-user-edit-form-valider:hover {
    background-color: #00ca00;
  }

  .btn-contact-user-edit-form-valider:active {
    background-color: #39dd39;
  }
}

@media (hover: none) {
  .btn-upload-img-user-edit:active {
    background: #d7d7d7;
  }

  .btn-contact-user-edit-form-valider:active {
    background-color: #00ca00;
  }
}
