.page-contact-form-grid {
  display: grid;
  width: 900px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  grid-template: repeat(8, auto) / 1fr 1fr;
  align-items: center;
}

.page-contact-form-grid-element-left {
  grid-column: 1;
  text-align: center;
}

.page-contact-form-grid-element-right {
  grid-column: 2;
  text-align: center;
}

.page-contact-form-grid-element-long {
  grid-column: 1 / span 2;
  text-align: center;
}

.page-contact-form-grid-element-semi-long-left {
  grid-column: 1;
  text-align: right;
  margin-right: 5px;
}

.page-contact-form-grid-element-semi-long-right {
  grid-column: 2;
  margin-left: 5px;
}

.page-contact-form-titre {
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  margin-right: 5px;
}

.msg-contact-titre,
.num-contact-titre,
.email-contact-titre {
  margin-right: 0 !important;
  display: block !important;
}

.msg-contact-titre {
  margin-top: 60px;
}

.contact-underline {
  text-decoration: underline;
}

.contact-star {
  color: #ff0000;
}

#nomcontact,
#prenomcontact {
  display: inline-block;
  margin-left: 5px;
}

#nomcontact,
#prenomcontact,
#numcontact,
#emailcontact,
#msgcontact {
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
}

#msgcontact {
  display: block;
  margin-left: auto;
  margin-right: auto;
  resize: vertical;
  width: 800px;
  height: 200px;
}

.btn-contact-form-envoyer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.contact-legende {
  text-align: right;
  margin-right: 15px;
  margin-bottom: 15px;
  font-size: 16px;
}

.contact-legende-star {
  font-weight: bold;
}

.msgErrContact {
  text-align: center;
  color: #ff0000;
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.msgSuccesContact {
  text-align: center;
  color: #00ff00;
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
}

@media (hover: hover) {
  .btn-contact-form-envoyer:hover {
    background-color: #00ca00;
  }

  .btn-contact-form-envoyer:active {
    background-color: #39dd39;
  }
}

@media (hover: none) {
  .btn-contact-form-envoyer:active {
    background-color: #00ca00;
  }
}

@media (max-width: 1127px) {
  .page-contact-form-grid {
    width: auto;
  }

  #msgcontact {
    width: 80%;
  }
}

@media (max-width: 750px) {
  .page-contact-form-grid {
    width: auto;
  }

  #nomcontact,
  #prenomcontact {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
  }

  .page-contact-form-grid-element-left p,
  .page-contact-form-grid-element-right p {
    display: block;
    margin-right: 0;
  }

  .page-contact-form-grid-element-semi-long-left {
    grid-column: 1 / span 2;
    text-align: center;
    margin-right: 0;
  }

  .page-contact-form-grid-element-semi-long-right {
    grid-column: 1 / span 2;
    margin-left: 0;
  }

  #numcontact,
  #emailcontact {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
