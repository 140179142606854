.form-cat-grid {
  display: grid;
  grid-template: 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr 0.1fr / 1fr;
}

.form-cat-titre-img,
.form-cat-titre-titre,
.form-cat-description-titre {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

#imgcat {
  display: none;
  visibility: hidden;
}

#titrecat,
#descriptioncat {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
}

#titrecat {
  width: 400px;
}

#descriptioncat {
  resize: none;
  width: 800px;
  height: 200px;
}

.btn-upload-img-cat {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 200px;

  padding-top: 2px;
  padding-bottom: 2px;

  text-align: center;
  font-weight: bold;
  background-color: #ffffff;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
  border-radius: 3px;
  cursor: pointer;
}

.titre-img-cat-deco {
  text-decoration: underline;
  font-weight: bold;
}

.btn-upload-img-cat-titre {
  text-align: center;
  font-style: italic;
}

.btn-cat-form-valider {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.msgErrAddUpdateCat {
  text-align: center;
  color: #ff0000;
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
}

@media (hover: hover) {
  .btn-upload-img-cat:hover {
    background-color: #a3a3a3;
  }

  .btn-upload-img-cat:active {
    background-color: #d7d7d7;
  }

  .btn-cat-form-valider:hover {
    background-color: #00ca00;
  }

  .btn-cat-form-valider:active {
    background-color: #39dd39;
  }
}

@media (hover: none) {
  .btn-cat-form-valider:active {
    background-color: #00ca00;
  }

  .btn-upload-img-cat:active {
    background: #d7d7d7;
  }
}

@media (max-width: 1080px) {
  #descriptioncat {
    width: 80%;
  }
}

@media (max-width: 640px) {
  #titrecat {
    width: 78.8%;
  }
}
