.page-body-accueil {
  position: relative;
}

.btn-edit-accueil {
  position: absolute;
  top: 30px;
  right: 85px;

  display: block;
  border: none;
  background: none;
  padding: 0;
  line-height: 0px;
  font-size: 20px;
  color: #0000ff;
  cursor: pointer;
}

@media (hover: hover) {
  .btn-edit-accueil:hover {
    color: #9999ff;
  }

  .btn-edit-accueil:active {
    color: #5959ff;
  }
}

@media (hover: none) {
  .btn-edit-accueil:active {
    color: #5959ff;
  }
}

@media (max-width: 968px) {
  .btn-edit-accueil {
    right: 30px;
  }
}
