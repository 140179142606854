html {
  font-family: "Inter";
  background: url("../../public/img/bg-img.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

img {
  pointer-events: none;
}

:not(input, textarea) {
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
}

.page-grid {
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header-btn-deconnecte {
  grid-column: 1 / span 3;
}

.page-body-grid {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  background-color: #ffffff;
  border: solid #000000 2px;
  border-radius: 15px;
  box-shadow: 0px 0px 25px rgb(40, 40, 40);

  display: grid;
  grid-template: auto 1fr auto / 1fr;
  grid-auto-flow: row;
}

.page-body-grid-header {
  grid-row: 1 / span 1;
}

.page-body-grid-content {
  grid-row: 2 / span 1;
}

.page-body-grid-footer {
  grid-row: 3 / span 1;
}

.footer-space {
  height: 20px;
  grid-row: 3 / span 1;
  grid-column: 1 / span 3;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@media (hover: hover) {
  .page-grid {
    grid-template: 100px 0.1fr 20px / 1fr 80% 1fr;
  }

  @media (max-width: 300px) {
    .page-grid {
      grid-template: 100px 0.1fr 20px / 1fr 240px 1fr;
    }
  }

  @media (min-width: 1920px) {
    .page-grid {
      grid-template: 100px 0.1fr 20px / 1fr 1532px 1fr;
    }
  }
}

@media (hover: none) {
  .page-grid {
    grid-template: 100px 0.1fr 20px / 1fr 90% 1fr;
  }

  @media (max-width: 300px) {
    .page-grid {
      grid-template: 100px 0.1fr 20px / 1fr 240px 1fr;
    }
  }

  @media (min-width: 1920px) {
    .page-grid {
      grid-template: 100px 0.1fr 20px / 1fr 1532px 1fr;
    }
  }
}
