.certif-card > h2 {
  text-align: center;
  text-decoration: underline;
  font-size: 20px;
  margin-top: 0;
}

.certif-card > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.certif-card > a {
  font-size: 16px;
}

.certif-card-liens-grid {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  margin-top: 15px;
  margin-bottom: 15px;
}

.certif-card-liens-grid-element {
  text-align: center;
}

.certif-card-liens-grid-element-long {
  grid-column: 1 / span 2;
}

.update-certif-btn-retour {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  color: #000000;
  line-height: 16px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

@media (hover: hover) {
  .update-certif-btn-retour:hover {
    color: #c8c8c8;
  }

  .update-certif-btn-retour:active {
    color: #7f7f7f;
  }
}
@media (hover: none) {
  .update-certif-btn-retour:active {
    color: #7f7f7f;
  }
}
