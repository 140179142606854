.add-cpt-grid {
  display: grid;
  grid-template: 25px 0.1fr 18px / 1fr;
  margin-bottom: 15px;
}

.add-cpt-btn {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  color: #000000;
  line-height: 0px;
  font-size: 70px;
  cursor: pointer;
}

.add-cpt-btn-retour {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  color: #000000;
  line-height: 16px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.add-cpt-grid-element-nav {
  grid-row: 1 / span 1;
}

.add-cpt-grid-element-form {
  grid-row: 2 / span 1;
}

.add-cpt-grid-element-retour {
  grid-row: 3 / span 1;
  height: 16px;
}

.add-cpt-grid-element-nav ul {
  padding: 0;
  margin: 0;
  text-align: center;
}

.add-cpt-grid-element-nav li {
  display: inline;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
}

.add-cpt-grid-element-nav-li {
  position: relative;
  color: black;
  text-decoration: none;

  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  cursor: pointer;
}

.add-cpt-grid-element-nav-li::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  top: 20px;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.add-cpt-grid-element-nav-li-focus {
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: black;
  color: black;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;

  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  cursor: pointer;
}

.add-cpt-grid-element-nav-li:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media (max-width: 400px) {
  .add-cpt-grid-element-nav li {
    font-size: 11px;
  }
}

@media (hover: hover) {
  .add-cpt-btn:hover {
    color: #c8c8c8;
  }

  .add-cpt-btn:active {
    color: #7f7f7f;
  }

  .add-cpt-btn-retour:hover {
    color: #c8c8c8;
  }

  .add-cpt-btn-retour:active {
    color: #7f7f7f;
  }
}

@media (hover: none) {
  .add-cpt-btn:active {
    color: #7f7f7f;
  }

  .add-cpt-btn-retour:active {
    color: #7f7f7f;
  }
}
