.moyens-contact-grid {
  display: grid;
  grid-template: repeat(4, auto) / repeat(2, auto);
  align-items: center;
  margin-left: 70px;
}

.moyens-contact-grid-element p,
.moyens-contact-grid-element a {
  display: inline-block;
  font-size: 16px;
}

.moyens-contact-grid-element p {
  text-align: right;
  margin-right: 5px;
  font-weight: bold;
}

.moyens-contact-grid-element-long {
  grid-column: 1 / span 2;
}

.text-underline {
  text-decoration: underline;
}

.moyens-contact-grid-element-centrer {
  display: none;
}

.moyens-contact-grid-element-centrer-mail {
  margin-bottom: 20px;
}

@media (max-width: 750px) {
  .num-long,
  .mail-long {
    display: none !important;
  }

  .moyens-contact-grid-element-centrer {
    display: block;
  }

  .moyens-contact-grid {
    margin-left: 0;
  }

  .moyens-contact-grid-element {
    text-align: center;
  }
}
