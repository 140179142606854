.grid-separateur {
  display: grid;
  grid-template: 2px 1fr / 1fr;
}

.grid-separateur-ligne {
  display: block;
  height: 2px;
  background-color: #bdbdbd;
}

.separateur-titre {
  font-size: 16px;
  margin-top: 3px;
  margin-bottom: 0;
  margin-left: 17px;
  color: #bdbdbd;
}

.separateur-titre-ligne-null {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 17px;
  color: #bdbdbd;
}
