.cpt-flex {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  margin-left: 29px;
  margin-right: 29px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.cpt-flex-header {
  margin-top: 15px !important;
}

.cpt-flex-element {
  position: relative;

  flex-shrink: 0;
}

.cpt-flex-element-long {
  position: relative;

  flex-basis: 100%;
  flex-shrink: 0;
}

.lien-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  gap: 15px;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.form-modif-cpt-grid {
  margin-top: 10px;
}

.lien-flex-element {
  flex-shrink: 0;
  flex-basis: 160px;
}

.btn-delete-cpt,
.btn-delete-cpt-long {
  position: absolute;
  top: 5px;
  right: 5px;

  display: block;
  border: none;
  background: none;
  padding: 0;
  line-height: 0px;
  font-size: 20px;
  color: #ff0000;
  cursor: pointer;
}

.btn-edit-cpt,
.btn-edit-cpt-long {
  position: absolute;
  top: 5px;
  right: 30px;

  display: block;
  border: none;
  background: none;
  padding: 0;
  line-height: 0px;
  font-size: 20px;
  color: #0000ff;
  cursor: pointer;
}

.update-cpt-btn-retour {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  color: #000000;
  line-height: 16px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.msg-err-delete {
  text-align: center;
  color: #ff0000;
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
}

@media (hover: hover) {
  .btn-delete-cpt:hover,
  .btn-delete-cpt-long:hover {
    color: #ff9999;
  }

  .btn-delete-cpt:active,
  .btn-delete-cpt-long:active {
    color: #ff5959;
  }

  .btn-edit-cpt:hover,
  .btn-edit-cpt-long:hover {
    color: #9999ff;
  }

  .btn-edit-cpt:active,
  .btn-edit-cpt-long:active {
    color: #5959ff;
  }

  .update-cpt-btn-retour:hover {
    color: #c8c8c8;
  }

  .update-cpt-btn-retour:active {
    color: #7f7f7f;
  }
}

@media (hover: none) {
  .btn-delete-cpt:active,
  .btn-delete-cpt-long:active {
    color: #ff5959;
  }

  .btn-edit-cpt:active,
  .btn-edit-cpt-long:active {
    color: #5959ff;
  }

  .update-cpt-btn-retour:active {
    color: #7f7f7f;
  }
}

@media (max-width: 968px) {
  .cpt-flex {
    justify-content: space-evenly;
  }
}
