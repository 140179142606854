.page-accueil-grid-form {
  display: grid;
  grid-template: 50px auto 50px auto auto auto auto / 1fr;
  align-items: center;
  margin-top: 25px;
}

.qsj-img-form,
.mpf-img-form {
  height: 35px;
  width: 35px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 40px;
}

.qsj-titre-form,
.mpf-titre-form {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  vertical-align: middle;
  text-decoration: underline;
  font-weight: bold;
  margin-left: 15px;
  font-size: 20px;
}

#mpfTextArea,
#qsjTextArea {
  resize: none;
  width: 80%;
  text-align: justify;
  height: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 35px;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
}

.btn-acc-form-valider {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.btn-acc-retour {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  color: #000000;
  line-height: 16px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.msgModifAccErr {
  text-align: center;
  color: #ff0000;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

@media (hover: hover) {
  .btn-acc-form-valider:hover {
    background-color: #00ca00;
  }

  .btn-acc-form-valider:active {
    background-color: #39dd39;
  }

  .btn-acc-retour:hover {
    color: #c8c8c8;
  }

  .btn-acc-retour:active {
    color: #7f7f7f;
  }
}

@media (hover: none) {
  .btn-acc-form-valider:active {
    background-color: #00ca00;
  }

  .btn-acc-retour:active {
    color: #7f7f7f;
  }
}

@media (max-width: 968px) {
  .page-accueil-grid-form-element-titre {
    text-align: center;
  }

  .qsj-img-form,
  .mpf-img-form {
    margin-left: 0;
  }
}
