.form-grid-login {
  display: grid;
  grid-template: repeat(7, auto) / 1fr;
  align-items: center;

  margin-top: 25px;
  margin-bottom: 15px;
}

.login-user-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.login-titre {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 24px;
}

.form-grid-login-element h2 {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  font-size: 19px;
}

.mdp-titre-margin {
  margin-top: 25px;
}

@media (max-width: 325px) {
  .login-titre {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.form-grid-login-element-input-mdp {
  text-align: center;
  margin-left: 5px;
}

.input-mdp-login {
  display: inline !important;
  margin-right: 5px !important;
}

.eye-psw-login-visible {
  margin-left: 6px;
}

.eye-psw-login-invisible {
  margin-left: 5px;
}

.eye-psw-login {
  display: inline !important;
  cursor: pointer;
  color: #000000;
  position: absolute !important;
  margin-top: 3.5px;
}

.form-grid-login-element input {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 100px;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
}

.form-grid-login-element button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.message-err-login {
  color: #ff0000;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

@media (hover: hover) {
  .eye-psw-login:hover {
    color: #a3a3a3;
  }

  .eye-psw:active {
    color: #d7d7d7;
  }

  .form-grid-login-element button:hover {
    background-color: #00ca00;
  }

  .form-grid-login-element button:active {
    background-color: #39dd39;
  }
}

@media (hover: none) {
  .eye-psw-login:active {
    color: #d7d7d7;
  }

  .form-grid-login-element button:active {
    background-color: #39dd39;
  }
}
