.header-cpt-grid {
  display: grid;
  grid-template: 0.1fr 0.1fr 0.1fr / 1fr;
}

.header-cpt-grid-element-titre {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 21px;
  margin-left: 30px;
  margin-right: 30px;
}

.header-cpt-titre {
  font-weight: normal;
  font-size: 32px;
  display: inline;
}

.header-cpt-titre-lib,
.header-cpt-desc-lib {
  font-weight: bold;
  text-decoration: underline;
}

.header-cpt-desc {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  text-align: justify;
  font-size: 20px;
}

.border-bottom-cpt-grid {
  border-bottom: solid #606060 2px;
}

.header-cpt-btn-retour {
  display: inline-block;
  border-radius: 50%;
  padding: 3px 5px 3px 5px;
  font-size: 24px;
  margin-right: 15px;

  border: solid #000000 2px;
  background-color: #0051ff;
  cursor: pointer;
}

.header-cpt-img {
  display: block;
  margin-left: auto;
  margin-right: auto;

  border: solid #000000 2px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgb(40, 40, 40);
}

@media (max-width: 968px) {
  .header-cpt-titre {
    font-size: 26px;
  }

  .header-cpt-desc {
    font-size: 16px;
  }

  .header-cpt-btn-retour {
    font-size: 19px;
    margin-right: 10px;
  }

  .header-cpt-img {
    height: 100%;
    width: 80%;
  }
}

@media (hover: hover) {
  .header-cpt-btn-retour:hover {
    background-color: #008cff;
  }

  .header-cpt-btn-retour:active {
    background-color: #0000ff;
  }
}

@media (hover: none) {
  .header-cpt-btn-retour:active {
    background-color: #0000ff;
  }
}
