.form-certif-grid {
  display: grid;
  grid-template: repeat(6, 0.1fr) / 1fr;
  margin-top: 10px;
}

.form-certif-titre-titre,
.form-certif-titre-img,
.form-certif-liens-titre {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

#titrecertif {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
  width: 400px;
}

#imgcertif {
  display: none;
  visibility: hidden;
}

.btn-upload-img-certif {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 200px;

  padding-top: 2px;
  padding-bottom: 2px;

  text-align: center;
  font-weight: bold;
  background-color: #ffffff;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
  border-radius: 3px;
  cursor: pointer;
}

.btn-upload-img-certif-titre {
  text-align: center;
  font-style: italic;
}

.titre-img-certif-deco {
  text-decoration: underline;
  font-weight: bold;
}

.form-certif-liens-grid {
  display: grid;
  grid-template-columns: 1fr;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.form-certif-liens-grid-form {
  display: grid;
  grid-template: 1fr / 1fr 1fr 45px;
  align-items: center;
}

.form-certif-liens-grid-form-titre {
  grid-column: 1 / span 1;
}

.form-certif-liens-grid-form-lien {
  grid-column: 2 / span 1;
}

.form-certif-liens-grid-form-delete {
  grid-column: 3 / span 1;
}

.certif-lien,
.certif-lien-titre {
  display: inline;
  font-weight: bold;
  margin-right: 3px;
  text-decoration: underline;
}

.titre-lien-certif,
.lien-certif {
  display: inline;
  margin-left: 3px;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
}

.form-certif-liens-grid-form-titre,
.form-certif-liens-grid-form-lien {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-certif-add-lien {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  color: #000000;
  line-height: 0px;
  font-size: 30px;
  cursor: pointer;
}

.btn-certif-delete-lien {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  line-height: 0px;
  font-size: 20px;
  color: #ff0000;
  cursor: pointer;
}

.btn-certif-form-valider {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.msgErrAddUpdateCertif {
  text-align: center;
  color: #ff0000;
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
}

@media (max-width: 650px) {
  #titrecertif {
    width: 78.8%;
  }
}

@media (max-width: 705px) {
  .certif-lien,
  .certif-lien-titre {
    margin-right: 0;
  }

  .titre-lien-certif,
  .lien-certif {
    display: block;
    margin-top: 6px;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
}

@media (max-width: 1060px) {
  .form-certif-liens-grid {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (hover: none) {
  .btn-upload-img-certif:active {
    background: #d7d7d7;
  }

  .btn-certif-add-lien:active {
    color: #7f7f7f;
  }

  .btn-certif-delete-lien:active {
    color: #ff5959;
  }

  .btn-certif-form-valider:active {
    background-color: #00ca00;
  }
}

@media (hover: hover) {
  .btn-upload-img-certif:hover {
    background-color: #a3a3a3;
  }

  .btn-upload-img-certif:active {
    background-color: #d7d7d7;
  }

  .btn-certif-add-lien:hover {
    color: #c8c8c8;
  }

  .btn-certif-add-lien:active {
    color: #7f7f7f;
  }

  .btn-certif-delete-lien:hover {
    color: #ff9999;
  }

  .btn-certif-delete-lien:active {
    color: #ff5959;
  }

  .btn-certif-form-valider:hover {
    background-color: #00ca00;
  }

  .btn-certif-form-valider:active {
    background-color: #39dd39;
  }
}
