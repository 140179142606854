.page-body-content-contact {
  position: relative;
}

.contact-titre {
  text-decoration: underline;
  margin-left: 30px;
  font-size: 24px;
  margin-top: 0;
  word-break: break-word;
}

.contact-titre-form {
  margin-top: 40px;
  font-size: 24px;
}

.contact-titre-moyens {
  margin-top: 25px;
}

.btn-edit-contact {
  position: absolute;
  top: -5px;
  right: 50px;

  display: block;
  border: none;
  background: none;
  padding: 0;
  line-height: 0px;
  font-size: 20px;
  color: #0000ff;
  cursor: pointer;
}

@media (hover: hover) {
  .btn-edit-contact:hover {
    color: #9999ff;
  }

  .btn-edit-contact:active {
    color: #5959ff;
  }
}

@media (hover: none) {
  .btn-edit-contact:active {
    color: #5959ff;
  }
}

@media (max-width: 750px) {
  .contact-titre {
    margin-left: 25px;
    margin-right: 25px;
    text-align: center;
  }
}

@media (max-width: 968px) {
  .btn-edit-contact {
    right: 20px;
    top: -20px;
  }
}
