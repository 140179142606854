.grid-otp {
  display: grid;
  grid-template: repeat(4, auto) / 1fr;
  margin-top: 35px;
}

.grid-otp-element h2 {
  font-weight: bold;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
  text-decoration: underline;
}

.grid-otp-element img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

#OTP {
  display: block;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 53px;
  text-align: center;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
}

.btn-otp-form-valider {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 30px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.message-err-otp {
  color: #ff0000;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

@media (hover: hover) {
  .btn-otp-form-valider:hover {
    background-color: #00ca00;
  }

  .btn-otp-form-valider:active {
    background-color: #39dd39;
  }
}

@media (hover: none) {
  .btn-otp-form-valider:active {
    background-color: #00ca00;
  }
}
