.form-grid-register {
  display: grid;
  grid-template: repeat(1, 1fr) / 5% repeat(4, 1fr) 5%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  border: solid #000000 2px;
  border-radius: 15px;
  box-shadow: 0px 0px 5px rgb(40, 40, 40);
  background-color: #efeeed;
}

.form-grid-register-element-long {
  grid-column: 1 / span 6;
}

.form-grid-register-element-err-long {
  grid-column: 1 / span 6;
}

.form-grid-register-element-long p {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
}
.form-grid-register-element-long input {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.form-grid-register-element-long button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 15px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.form-grid-register-element-court p {
  display: inline;
  text-align: left;
  margin-right: 5px;
  text-decoration: underline;
  font-weight: bold;
}

.form-grid-register-element-court input {
  display: inline;
  margin-top: 15px;
  margin-left: 5px;
  width: 100px;

  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
}

.form-grid-register-element-err-long p {
  text-align: center;
}

.form-grid-register-element-long-input {
  width: 100px;

  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
}

@media (min-width: 837px) {
  .form-grid-register-element-court-prenom {
    grid-column: 4 / span 2;
    text-align: center;
  }

  .form-grid-register-element-court-nom {
    grid-column: 2 / span 2;
    text-align: center;
  }

  .form-grid-register-element-court-num {
    grid-column: 2 / span 2;
    text-align: center;
  }

  .form-grid-register-element-court-email {
    grid-column: 4 / span 2;
    text-align: center;
  }
}

@media (max-width: 836px) {
  .form-grid-register {
    grid-template: repeat(1, 1fr) / repeat(1, 1fr);
  }

  .form-grid-register-element-court {
    grid-column: 1 / span 1;
  }

  .form-grid-register-element-long button {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .form-grid-register-element-long-imgInput p {
    margin-bottom: 3px;
  }

  .form-grid-register-element-court-prenom p {
    display: block;
    margin-right: 0px;
    text-align: center;
  }

  .form-grid-register-element-court-prenom input {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .form-grid-register-element-court-nom p {
    display: block;
    margin-right: 0px;
    text-align: center;
  }

  .form-grid-register-element-court-nom input {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .form-grid-register-element-court-num p {
    display: block;
    margin-right: 0px;
    text-align: center;
  }

  .form-grid-register-element-court-num input {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .form-grid-register-element-court-email p {
    display: block;
    margin-right: 0px;
    text-align: center;
  }

  .form-grid-register-element-court-email input {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

#img {
  display: none;
  visibility: hidden;
}

.btn-upload-img {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 200px;

  padding-top: 2px;
  padding-bottom: 2px;

  text-align: center;
  font-weight: bold;
  background-color: #ffffff;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
  border-radius: 3px;
  cursor: pointer;
}

.titre-img-deco {
  text-decoration: underline;
  font-weight: bold;
}

.form-grid-register-element-long-imgInput {
  grid-column: 1 / span 6;
}

.form-grid-register-element-long-imgInput p {
  text-align: center;
  font-style: italic;
}

.form-grid-register-element-titre-cat {
  grid-column: 1 / span 6;
}

.form-grid-register-element-titre-cat h2 {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  font-size: 20px;
}

.titre-cat-espace h2 {
  margin-top: 50px;
}

.message-err-register {
  margin-top: 0px;
  color: #ff0000;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 264px) {
  .btn-upload-img {
    width: 130px;
    font-size: 11px;
  }
}

.form-grid-register-element-input-mdp {
  text-align: center;
}

.input-mdp {
  display: inline !important;
  margin-right: 5px !important;
}

.eye-psw-visible {
  margin-left: 6px;
}

.eye-psw-invisible {
  margin-left: 5px;
}

.eye-psw {
  display: inline !important;
  cursor: pointer;
  color: #000000;
  position: absolute !important;
}

@media (hover: hover) {
  .btn-upload-img:hover {
    background-color: #a3a3a3;
  }

  .btn-upload-img:active {
    background-color: #d7d7d7;
  }

  .form-grid-register-element-long button:hover {
    background-color: #00ca00;
  }

  .form-grid-register-element-long button:active {
    background-color: #39dd39;
  }

  .eye-psw:hover {
    color: #a3a3a3;
  }

  .eye-psw:active {
    color: #d7d7d7;
  }
}

@media (hover: none) {
  .btn-upload-img:active {
    background: #d7d7d7;
  }

  .form-grid-register-element-long button:active {
    background-color: #00ca00;
  }

  .eye-psw:active {
    color: #d7d7d7;
  }
}
