.form-prj-grid {
  display: grid;
  grid-template: repeat(11, 0.1fr) / 1fr;
}

.form-prj-liens-grid {
  display: grid;
  grid-template-columns: 1fr;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.form-prj-liens-grid-form {
  display: grid;
  grid-template: 1fr / 1fr 1fr 45px;
  align-items: center;
}

.form-prj-liens-grid-form-titre {
  grid-column: 1 / span 1;
}

.form-prj-liens-grid-form-lien {
  grid-column: 2 / span 1;
}

.form-prj-liens-grid-form-delete {
  grid-column: 3 / span 1;
}

.form-prj-titre-img,
.form-prj-titre-icon,
.form-prj-titre-titre,
.form-prj-description-titre,
.form-prj-liens-titre {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

#imgprj,
#iconprj {
  display: none;
  visibility: hidden;
}

#titreprj,
#descriptionprj {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
}

#titreprj {
  width: 400px;
}

#descriptionprj {
  resize: none;
  width: 800px;
  height: 200px;
}

.btn-upload-img-prj,
.btn-upload-icon-prj {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 200px;

  padding-top: 2px;
  padding-bottom: 2px;

  text-align: center;
  font-weight: bold;
  background-color: #ffffff;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
  border-radius: 3px;
  cursor: pointer;
}

.titre-img-prj-deco,
.titre-icon-prj-deco {
  text-decoration: underline;
  font-weight: bold;
}

.btn-upload-img-prj-titre,
.btn-upload-icon-prj-titre {
  text-align: center;
  font-style: italic;
}

.btn-prj-form-valider {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.prj-lien,
.prj-lien-titre {
  display: inline;
  font-weight: bold;
  margin-right: 3px;
  text-decoration: underline;
}

.titre-lien-prj,
.lien-prj {
  display: inline;
  margin-left: 3px;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
}

.form-prj-liens-grid-form-titre,
.form-prj-liens-grid-form-lien {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn-prj-add-lien {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  color: #000000;
  line-height: 0px;
  font-size: 30px;
  cursor: pointer;
}

.btn-prj-delete-lien {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  line-height: 0px;
  font-size: 20px;
  color: #ff0000;
  cursor: pointer;
}

.msgErrAddUpdatePrj {
  text-align: center;
  color: #ff0000;
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
}

@media (hover: hover) {
  .btn-upload-img-prj:hover {
    background-color: #a3a3a3;
  }

  .btn-upload-img-prj:active {
    background-color: #d7d7d7;
  }

  .btn-upload-icon-prj:hover {
    background-color: #a3a3a3;
  }

  .btn-upload-icon-prj:active {
    background-color: #d7d7d7;
  }

  .btn-prj-form-valider:hover {
    background-color: #00ca00;
  }

  .btn-prj-form-valider:active {
    background-color: #39dd39;
  }

  .btn-prj-add-lien:hover {
    color: #c8c8c8;
  }

  .btn-prj-add-lien:active {
    color: #7f7f7f;
  }

  .btn-prj-delete-lien:hover {
    color: #ff9999;
  }

  .btn-prj-delete-lien:active {
    color: #ff5959;
  }
}

@media (hover: none) {
  .btn-prj-form-valider:active {
    background-color: #00ca00;
  }

  .btn-upload-img-prj:active {
    background: #d7d7d7;
  }

  .btn-upload-icon-prj:active {
    background: #d7d7d7;
  }

  .btn-prj-add-lien:active {
    color: #7f7f7f;
  }

  .btn-prj-delete-lien:active {
    color: #ff5959;
  }
}

@media (max-width: 1080px) {
  #descriptionprj {
    width: 80%;
  }
}

@media (max-width: 1060px) {
  .form-prj-liens-grid {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 705px) {
  .prj-lien,
  .prj-lien-titre {
    margin-right: 0;
  }

  .titre-lien-prj,
  .lien-prj {
    display: block;
    margin-top: 6px;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
}

@media (max-width: 650px) {
  #titreprj {
    width: 78.8%;
  }
}
