.grid-otp-edit {
  display: grid;
  width: 900px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  grid-template: repeat(4, auto) / 1fr 1fr;
  align-items: center;
}

.grid-otp-edit-element-titre {
  grid-column: 1 / span 2;
  text-align: center;
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 10px;
}

.grid-otp-edit-element-titre h2 {
  margin-left: 20px;
  margin-right: 20px;
}

.otp-edit-form-titre {
  font-weight: bold;
  font-size: 20px;
  text-decoration: underline;
  margin-right: 5px;
}

.otp-edit-form-titre-left {
  text-align: right;
}

.btn-actv-dectv-otp-edit {
  display: block;
  margin-left: 5px;

  padding-top: 8px;
  padding-bottom: 8px;

  text-align: center;
  font-weight: bold;
  background-color: #ffffff;
  border: solid #000000 2px;
  color: #000000;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
  border-radius: 3px;
  cursor: pointer;
}

.btn-dectv-otp-edit {
  width: 90px;
}

.btn-actv-otp-edit {
  width: 70px;
}

.grid-otp-edit-element-long {
  grid-column: 1 / span 2;
}

.grid-otp-edit-element-long img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#OTPEdit {
  display: block;
  margin-top: 7.5px;
  margin-left: auto;
  margin-right: auto;
  width: 53px;
  text-align: center;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
}

.btn-contact-otp-edit-form-valider {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 30px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.message-err-res-otp-edit-contact {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.message-err-otp-edit-contact {
  color: #ff0000;
}

.message-res-otp-edit-contact {
  color: #00ff00;
}

@media (max-width: 1127px) {
  .grid-otp-edit {
    width: auto;
  }
}

@media (max-width: 750px) {
  .grid-otp-edit {
    width: auto;
  }

  .grid-otp-edit-element-left {
    grid-column: 1 / span 2;
  }

  .grid-otp-edit-element-left p {
    text-align: center;
    margin-right: 0;
  }

  .grid-otp-edit-element-right {
    grid-column: 1 / span 2;
    margin-left: 0;
    text-align: center;
  }

  .btn-actv-dectv-otp-edit {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
}

@media (max-width: 264px) {
  .btn-actv-dectv-otp-edit {
    width: 130px;
    font-size: 11px;
  }
}

@media (hover: hover) {
  .btn-actv-dectv-otp-edit:hover {
    background-color: #a3a3a3;
  }

  .btn-actv-dectv-otp-edit:active {
    background-color: #d7d7d7;
  }

  .btn-contact-otp-edit-form-valider:hover {
    background-color: #00ca00;
  }

  .btn-contact-otp-edit-form-valider:active {
    background-color: #39dd39;
  }
}

@media (hover: none) {
  .btn-actv-dectv-otp-edit:active {
    background: #d7d7d7;
  }

  .btn-contact-otp-edit-form-valider:active {
    background-color: #00ca00;
  }
}
