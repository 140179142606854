.page-body-cv {
  position: relative;
  width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.btn-edit-cv {
  position: absolute;
  top: -10px;
  right: -40px;

  display: block;
  border: none;
  background: none;
  padding: 0;
  line-height: 0px;
  font-size: 20px;
  color: #0000ff;
  cursor: pointer;
}

@media (hover: hover) {
  .btn-edit-cv:hover {
    color: #9999ff;
  }

  .btn-edit-cv:active {
    color: #5959ff;
  }
}

@media (hover: none) {
  .btn-edit-cv:active {
    color: #5959ff;
  }
}

@media (max-width: 968px) {
  .page-body-cv {
    position: relative;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }

  .btn-edit-cv {
    top: -5px;
    right: 13px;
  }
}
