.grid-contact-modif-form {
  display: grid;
  grid-template: repeat(4, auto) / 1fr;
}

.btn-contact-retour {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #000000;
  line-height: 16px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

@media (hover: none) {
  .btn-contact-retour:active {
    color: #7f7f7f;
  }
}

@media (hover: hover) {
  .btn-contact-retour:hover {
    color: #c8c8c8;
  }

  .btn-contact-retour:active {
    color: #7f7f7f;
  }
}
