.header-btn-deconnecte i {
  display: block;

  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;

  padding-top: 3px;
  padding-bottom: 3px;

  width: 120px;

  position: relative;

  text-align: center;
  font-style: normal;
  font-weight: bold;

  border: solid #000000 2px;
  border-radius: 5px;
  background-color: #ff0000;
  box-shadow: 0px 0px 5px rgb(40, 40, 40);

  cursor: pointer;
}

@media (hover: hover) {
  .header-btn-deconnecte i:hover {
    background-color: #ff9999;
  }

  .header-btn-deconnecte i:active {
    background-color: #ff5959;
  }
}

@media (hover: none) {
  .header-btn-deconnecte i:active {
    background-color: #ff5959;
  }
}
