.register-titre-grid {
  display: grid;
  grid-template: 200px 0.5fr 3fr / 1fr;
  margin-left: auto;
  margin-right: auto;
}

.register-user-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;

  height: 150px;
  width: 150px;
}

.register-titre {
  text-decoration: underline;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 45px;
  text-transform: uppercase;
}

.register-paragraphe-debut {
  text-align: justify;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.register-paragraphe-tab {
  text-align: justify;
  padding-left: 65px;
  padding-right: 65px;
}

.register-paragraphe-fin {
  text-align: justify;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media (max-width: 325px) {
  .register-titre {
    padding-left: 10px;
    padding-right: 10px;
  }

  .register-paragraphe-debut {
    padding-left: 20px;
    padding-right: 20px;
  }

  .register-paragraphe-tab {
    padding-left: 40px;
    padding-right: 40px;
  }

  .register-paragraphe-fin {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 400px) {
  .register-titre {
    font-size: 16px;
  }

  .register-user-icon {
    height: 100px;
    width: 100px;
  }

  .register-titre-grid {
    grid-template: 150px 0.1fr 1fr / 1fr;
  }
}
