.page-cv-grid-form {
  display: grid;
  grid-template: repeat(7, auto) / 1fr;
  margin-top: 10px;
}

.img-preview-titre,
.cv-pdf-titre {
  text-align: center;
  text-decoration: underline;
  font-weight: bold;
  font-size: 20px;
}

.img-preview-titre {
  margin-top: 15px;
}

.cv-pdf-titre {
  margin-top: 30px;
}

.btn-upload-img-cv {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 200px;

  padding-top: 2px;
  padding-bottom: 2px;

  text-align: center;
  font-weight: bold;
  background-color: #ffffff;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
  border-radius: 3px;
  cursor: pointer;
}

.btn-upload-img-cv-titre {
  text-align: center;
  font-style: italic;
}

.titre-img-cv-deco {
  text-decoration: underline;
  font-weight: bold;
}

.btn-upload-pdf-cv {
  display: block;
  margin-left: auto;
  margin-right: auto;

  width: 200px;

  padding-top: 2px;
  padding-bottom: 2px;

  text-align: center;
  font-weight: bold;
  background-color: #ffffff;
  border: solid #000000 2px;
  box-shadow: 0px 0px 2px rgb(40, 40, 40);
  border-radius: 3px;
  cursor: pointer;
}

.btn-upload-pdf-cv-titre {
  text-align: center;
  font-style: italic;
}

.titre-pdf-cv-deco {
  text-decoration: underline;
  font-weight: bold;
}

#imgcv {
  display: none;
  visibility: hidden;
}

#pdfcv {
  display: none;
  visibility: hidden;
}

.btn-cv-retour {
  display: block;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #000000;
  line-height: 16px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.btn-cv-form-valider {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 15px;

  border: solid #000000 2px;
  border-radius: 50px;
  font-weight: bold;
  background-color: #00ff00;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.msgErrEditCv {
  text-align: center;
  color: #ff0000;
  margin-top: 0;
  margin-left: 20px;
  margin-right: 20px;
}

@media (hover: none) {
  .btn-upload-img-cv:active {
    background: #d7d7d7;
  }

  .btn-upload-pdf-cv:active {
    background: #d7d7d7;
  }

  .btn-cv-retour:active {
    color: #7f7f7f;
  }

  .btn-cv-form-valider:active {
    background-color: #00ca00;
  }
}

@media (hover: hover) {
  .btn-upload-img-cv:hover {
    background-color: #a3a3a3;
  }

  .btn-upload-img-cv:active {
    background-color: #d7d7d7;
  }

  .btn-upload-pdf-cv:hover {
    background-color: #a3a3a3;
  }

  .btn-upload-pdf-cv:active {
    background-color: #d7d7d7;
  }

  .btn-cv-retour:hover {
    color: #c8c8c8;
  }

  .btn-cv-retour:active {
    color: #7f7f7f;
  }

  .btn-cv-form-valider:hover {
    background-color: #00ca00;
  }

  .btn-cv-form-valider:active {
    background-color: #39dd39;
  }
}
